import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
function initializeDatadog() {
    if (navigator.webdriver) {
        return;
    }
    let clientToken;
    let applicationId;
    let service;
    let env;
    if (window.RAILS_ENV === 'production') {
        clientToken = 'pub66e9dba9a20645e96ab194b8167ed4d0';
        applicationId = '25a1d05c-2f3b-4e2e-959f-1eb40da94e1c';
        service = 'creditninja-prod';
        env = 'production';
    }
    else {
        clientToken = 'pub8d536e85da7762cdf679cd1c62cabdd7';
        applicationId = '95662fb5-b8cf-4c41-9f48-c9140c7055a1';
        service = 'creditninja-staging';
        // eslint-disable-next-line prefer-destructuring
        env = window.location.host.split('.')[0];
    }
    datadogRum.setUser(window.user_data);
    datadogLogs.setUser(window.user_data);
    datadogRum.init({
        clientToken,
        applicationId,
        service,
        env,
        site: 'datadoghq.com',
        version: window.REVISION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        traceSampleRate: 100,
        defaultPrivacyLevel: 'allow',
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        trackFrustrations: true,
        startSessionReplayRecordingManually: true,
        storeContextsAcrossPages: true,
        allowedTracingUrls: [
            'https://staging.internal.creditninja.dev',
            'https://www.creditninja.com',
            'https://app.creditninja.com'
        ]
    });
    datadogLogs.init({
        clientToken,
        service,
        env,
        site: 'datadoghq.com',
        version: window.REVISION,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        forwardConsoleLogs: 'all'
    });
    if ((window.user_data && window.user_data.email) || window.location.pathname.match(/^\/registration/) !== null) {
        datadogRum.startSessionReplayRecording();
    }
}
initializeDatadog();
